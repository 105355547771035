<template>
  <header
    class="navbar z-10 animate__animated _placeholder"
    :class="{
      'top-6': mockedApp,
      'top-0': !mockedApp,
      'sticky bg-base-200 animate__faster shadow': signedIn,
      'animate__fadeInDown animate__faster':
        !hideHeader && pageTop! > -32 && width < 640,
      animate__fadeOutUp: hideHeader || (pageTop! <= -32 && width < 640),
    }">
    <div class="container mx-auto px-4 flex justify-between items-center">
      <div
        class="space-x-2 flex justify-center gap-0.5"
        :class="{
          'flex-1 justify-between': !signedIn,
          'pt-10': !signedIn && mockedApp,
        }">
        <NuxtLink
          v-if="!sessionStore.isEventInThePast"
          :to="
            localeRoute({ name: 'booths-eventId-invite', query: $route.query })
          "
          class="btn btn-outline btn-sm btn-square"
          :class="{
            'btn-disabled': $route.name
              ?.toString()
              .includes('booths-eventId-invite'),
          }">
          <PhQrCode size="20" weight="duotone" />
        </NuxtLink>
        <button class="btn btn-outline btn-sm btn-square" @click="applyLocale">
          <NuxtImg
            v-if="locale !== 'en'"
            src="https://flagcdn.com/20x15/gb.png"
            srcset="https://flagcdn.com/40x30/gb.png 2x,
    https://flagcdn.com/60x45/gb.png 3x"
            width="20"
            height="15"
            alt="United Kingdom" />
          <NuxtImg
            v-else
            src="https://flagcdn.com/20x15/gr.png"
            srcset="https://flagcdn.com/40x30/gr.png 2x,
    https://flagcdn.com/60x45/gr.png 3x"
            width="20"
            height="15"
            alt="Greece" />
        </button>
      </div>
      <GuestMenu v-if="signedIn" />
    </div>
  </header>
</template>

<script setup lang="ts">
  import { PhQrCode } from "@phosphor-icons/vue";
  import { useTimeout, useWindowSize } from "@vueuse/core";

  defineProps<{
    hideHeader: boolean;
  }>();

  const mockedApp = inject<boolean>("mockedApp", false);
  const sessionStore = useSessionStore();
  const localeRoute = useLocaleRoute();
  const { locale, setLocale } = useI18n();
  const { width } = useWindowSize();
  const pageTop = inject<Ref<number>>("pageTop");
  const router = useRouter();
  const signedIn = ref(true);
  const url = useRequestURL();

  if (mockedApp) {
    router.afterEach((to) => {
      signedIn.value = to.fullPath.includes("/capture");
    });
  }

  const applyLocale = async () => {
    await setLocale(locale.value === "en" ? "el" : "en");
    if (mockedApp) {
      location.search = "mocked=true";
      useTimeout(500, {
        callback: () => location.reload(),
      });
    } else {
      location.reload();
    }
  };

  sessionStore.auth?.onAuthStateChanged(() => {
    signedIn.value =
      (!mockedApp && (sessionStore.auth!.currentUser?.uid.length ?? 0) > 0) ||
      (mockedApp && url.pathname.includes("/capture"));
  });
</script>
