<template>
  <AppBackgroundDecoration />
  <div
    class="grow flex flex-col max-h-screen overflow-x-hidden overflow-y-auto"
    data-theme="instabooth-app">
    <div class="relative grow flex flex-col">
      <div
        v-if="isMocked"
        class="fixed h-8 z-20 w-full top-0 bg-base-200 px-8 flex justify-between items-center font-bold">
        <div class="text-xs">TELCOM</div>
        <div class="flex gap-1">
          <PhCellSignalFull weight="bold" />
          <div class="text-xs">5G</div>
          <PhBatteryHigh weight="bold" />
        </div>
      </div>
      <BoothHeader :hide-header="hideHeader" />
      <main
        class="flex-1 flex flex-col container mx-auto p-6"
        :class="{ 'pt-8 overflow-x-hidden': isMocked }">
        <slot />
      </main>
      <a id="main_bottom"></a>
      <BoothFooter v-if="!hideHeader" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import { PhBatteryHigh, PhCellSignalFull } from "@phosphor-icons/vue";
  import { getDownloadURL, ref as _ref } from "firebase/storage";

  const sessionStore = useSessionStore();
  const { eventId } = useRoute().params;
  const isGuest = ref(true);
  const isMocked = parent !== window;
  const route = useRoute();
  const hideHeader = ref(false);
  const url = useRequestURL();
  const { locale } = useI18n();
  provide("mockedApp", isMocked);
  const boothContentEditor = ref(false);
  provide("booth-content-editor", boothContentEditor);

  const tocPath = `toc/${eventId}.localizations-${locale.value.substring(0, 2)}.json`;
  try {
    const url = await getDownloadURL(_ref(sessionStore.storage!, tocPath));
    const json = await $fetch(url, {
      headers: { "contetnt-type": "application/json" },
    });
    sessionStore.seti18nContent({
      ...sessionStore.i18nContent,
      ...(json as any),
    });
  } catch (e) {}

  onMounted(() => {
    isGuest.value =
      sessionStore.auth?.currentUser?.providerData[0]?.providerId != "password";

    if (sessionStore.isEventInTheFuture() || sessionStore.isEventActive()) {
      useHead({
        link: [
          {
            href: `/booths/${route.params.eventId}/manifest.webmanifest`,
            rel: "manifest",
          },
        ],
      });
    }
  });

  if (isMocked) {
    window.addEventListener("message", async (ev) => {
      if (ev.origin === `${url.protocol}//${url.host}`) {
        if (ev.data === "refresh-event") {
          assertEventId(true);
        } else if (ev.data === "toggle-content-editor") {
          boothContentEditor.value = !boothContentEditor.value;
        }
      }
    });
  }
</script>

<style>
  body {
    padding-top: env(safe-area-inset-top);
  }
</style>
