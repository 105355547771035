<template></template>

<script setup lang="ts">
  import { getThemeUrl } from "~/utils";
  const css = await getThemeUrl();

  useHead({
    link: [
      {
        rel: "stylesheet",
        href: css,
      },
    ],
  });
</script>

<style>
  [data-theme="instabooth-app"] *,
  [data-theme="instabooth-app"] *::before,
  [data-theme="instabooth-app"] *::after {
    --tw-ring-color: oklch(var(--p) / 0.3);
  }

  [data-theme="instabooth-app"],
  [data-theme="instabooth-app"] * {
    scrollbar-width: none;
  }
</style>
